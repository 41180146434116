import moment from 'moment';

export const countHoursBetweenDates = (date1: Date, date2: Date): number =>
	moment(date2).diff(moment(date1), 'hours');

export const countHoursBetweenDatesMoreThen = (date1: Date, date2: Date, delta: number): boolean =>
	Math.abs(moment(date1).diff(moment(date2), 'hours')) >= delta;

export const countMinutesBetweenDates = (date1: Date, date2: Date) =>
	Math.abs(moment(date1).diff(moment(date2), 'minutes'));
