import { createSharedPathnamesNavigation } from 'next-intl/navigation';

export const LocalesMap = new Map([
	['ru', 'ru-RU'],
	['en', 'en-US'],
]);

const locales = ['ru', 'en'] as const;
const localePrefix = 'as-needed';

export const { Link, redirect, usePathname, useRouter } = createSharedPathnamesNavigation({
	locales,
	localePrefix,
});
