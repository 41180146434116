import { sign } from 'jsonwebtoken';

const DEV_SECRET = 'i$(hwe*^ghug$(ih(*eh46kjgf92456#$($(*^)';

export type Authority =
	| 'regular'
	| 'support'
	| 'srm_user'
	| 'srm_admin'
	| 'srm_editor'
	| 'srm_viewer'
	| 'srm_coordinator'
	| 'online_admin'
	| 'online_editor'
	| 'online_team';

export type Authorities = Authority[];

const defaultTokenObject = {
	sub: 'lk-oauth2|722045',
	user_name: 'alexey.solyanik@jugru.org',
	displayName: 'Alexey Solyanik',
	profile: 'https://oh-my-duck-dev.jugru.org/users/8NMqdu',
	iss: 'lk',
	authorities: [],
	picture:
		'https://lh3.googleusercontent.com/a-/AOh14GjmnNHnX0TTxjTUHVDNAl3QQxgQrTUjVrlaCbA2=s96-c',
	client_id: 'srm-client',
	lk_user_id: 722045,
	scope: ['regular'],
	lk_session_id: 155565,
	exp: 1737205754,
	lang: 'ru',
	jti: '934da0e8-1707-499d-8f12-5ea8826ec449',
	email: 'alexey.solyanik@jugru.org',
};

export type CreateJWT = { token: string; sign: string };

export const createJWT = (authorities: Authorities): CreateJWT => {
	const fullToken = sign({ ...defaultTokenObject, authorities }, DEV_SECRET);
	const fullTokenParts = fullToken.split('.');

	return {
		token: `${fullTokenParts[0]}.${fullTokenParts[1]}`,
		sign: fullTokenParts[2],
	};
};

export const createJWTFromObject = (object: object): CreateJWT => {
	const fullToken = sign({ ...object }, DEV_SECRET);
	const fullTokenParts = fullToken.split('.');

	return {
		token: `${fullTokenParts[0]}.${fullTokenParts[1]}`,
		sign: fullTokenParts[2],
	};
};
