export type CorruptedResponse = {
	error: string;
	message: string;
	status: number;
};

export const isCorruptedResponse = (res: any): res is CorruptedResponse => {
	return (
		Object.hasOwn(res, 'error') &&
		Object.hasOwn(res, 'message') &&
		Object.hasOwn(res, 'status') &&
		!isNaN(+res.status) &&
		+res.status >= 300
	);
};

export class ResponseError extends Error {
	constructor(private readonly res: CorruptedResponse) {
		super();
	}

	public get status() {
		return this.res.status;
	}

	public get message() {
		return this.res.message;
	}
}
