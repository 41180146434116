import { Locale } from '@/src/modules/i18n';

export type Country = {
	name: string;
	codeAlpha2ISO: string;
	codeDigitalISO: number;
};

export const countryList: Country[] = [
	{ name: 'country_australia', codeDigitalISO: 36, codeAlpha2ISO: 'AU' },
	{ name: 'country_austria', codeDigitalISO: 40, codeAlpha2ISO: 'AT' },
	{ name: 'country_azerbaijan', codeDigitalISO: 31, codeAlpha2ISO: 'AZ' },
	{ name: 'country_albania', codeDigitalISO: 8, codeAlpha2ISO: 'AL' },
	{ name: 'country_algeria', codeDigitalISO: 12, codeAlpha2ISO: 'DZ' },
	{ name: 'country_angola', codeDigitalISO: 24, codeAlpha2ISO: 'AO' },
	{ name: 'country_andorra', codeDigitalISO: 20, codeAlpha2ISO: 'AD' },
	{ name: 'country_antiguaAndBarbuda', codeDigitalISO: 28, codeAlpha2ISO: 'AG' },
	{ name: 'country_argentina', codeDigitalISO: 32, codeAlpha2ISO: 'AR' },
	{ name: 'country_armenia', codeDigitalISO: 51, codeAlpha2ISO: 'AM' },
	{ name: 'country_afghanistan', codeDigitalISO: 4, codeAlpha2ISO: 'AF' },
	{ name: 'country_bahamas', codeDigitalISO: 44, codeAlpha2ISO: 'BS' },
	{ name: 'country_bangladesh', codeDigitalISO: 50, codeAlpha2ISO: 'BD' },
	{ name: 'country_barbados', codeDigitalISO: 52, codeAlpha2ISO: 'BB' },
	{ name: 'country_bahrain', codeDigitalISO: 48, codeAlpha2ISO: 'BH' },
	{ name: 'country_belize', codeDigitalISO: 84, codeAlpha2ISO: 'BZ' },
	{ name: 'country_belarus', codeDigitalISO: 112, codeAlpha2ISO: 'BY' },
	{ name: 'country_belgium', codeDigitalISO: 56, codeAlpha2ISO: 'BE' },
	{ name: 'country_benin', codeDigitalISO: 204, codeAlpha2ISO: 'BJ' },
	{ name: 'country_bulgaria', codeDigitalISO: 100, codeAlpha2ISO: 'BG' },
	{ name: 'country_bolivia', codeDigitalISO: 68, codeAlpha2ISO: 'BO' },
	{ name: 'country_bosniaAndHerzegovina', codeDigitalISO: 70, codeAlpha2ISO: 'BA' },
	{ name: 'country_botswana', codeDigitalISO: 72, codeAlpha2ISO: 'BW' },
	{ name: 'country_brazil', codeDigitalISO: 76, codeAlpha2ISO: 'BR' },
	{ name: 'country_brunei', codeDigitalISO: 96, codeAlpha2ISO: 'BN' },
	{ name: 'country_burkinaFaso', codeDigitalISO: 854, codeAlpha2ISO: 'BF' },
	{ name: 'country_burundi', codeDigitalISO: 108, codeAlpha2ISO: 'BI' },
	{ name: 'country_bhutan', codeDigitalISO: 64, codeAlpha2ISO: 'BT' },
	{ name: 'country_vanuatu', codeDigitalISO: 548, codeAlpha2ISO: 'VU' },
	{ name: 'country_unitedKingdom', codeDigitalISO: 826, codeAlpha2ISO: 'GB' },
	{ name: 'country_hungary', codeDigitalISO: 348, codeAlpha2ISO: 'HU' },
	{ name: 'country_venezuela', codeDigitalISO: 862, codeAlpha2ISO: 'VE' },
	{ name: 'country_eastTimor', codeDigitalISO: 626, codeAlpha2ISO: 'TL' },
	{ name: 'country_vietnam', codeDigitalISO: 704, codeAlpha2ISO: 'VN' },
	{ name: 'country_gabon', codeDigitalISO: 266, codeAlpha2ISO: 'GA' },
	{ name: 'country_haiti', codeDigitalISO: 332, codeAlpha2ISO: 'HT' },
	{ name: 'country_guyana', codeDigitalISO: 328, codeAlpha2ISO: 'GY' },
	{ name: 'country_gambia', codeDigitalISO: 270, codeAlpha2ISO: 'GM' },
	{ name: 'country_ghana', codeDigitalISO: 288, codeAlpha2ISO: 'GH' },
	{ name: 'country_guatemala', codeDigitalISO: 320, codeAlpha2ISO: 'GT' },
	{ name: 'country_guinea', codeDigitalISO: 324, codeAlpha2ISO: 'GN' },
	{ name: 'country_guineaBissau', codeDigitalISO: 624, codeAlpha2ISO: 'GW' },
	{ name: 'country_germany', codeDigitalISO: 276, codeAlpha2ISO: 'DE' },
	{ name: 'country_honduras', codeDigitalISO: 340, codeAlpha2ISO: 'HN' },
	{ name: 'country_grenada', codeDigitalISO: 308, codeAlpha2ISO: 'GD' },
	{ name: 'country_greece', codeDigitalISO: 300, codeAlpha2ISO: 'GR' },
	{ name: 'country_georgia', codeDigitalISO: 268, codeAlpha2ISO: 'GE' },
	{ name: 'country_denmark', codeDigitalISO: 208, codeAlpha2ISO: 'DK' },
	{ name: 'country_djibouti', codeDigitalISO: 262, codeAlpha2ISO: 'DJ' },
	{ name: 'country_dominica', codeDigitalISO: 212, codeAlpha2ISO: 'DM' },
	{ name: 'country_dominicanRepublic', codeDigitalISO: 214, codeAlpha2ISO: 'DO' },
	{ name: 'country_egypt', codeDigitalISO: 818, codeAlpha2ISO: 'EG' },
	{ name: 'country_zambia', codeDigitalISO: 894, codeAlpha2ISO: 'ZM' },
	{ name: 'country_zimbabwe', codeDigitalISO: 716, codeAlpha2ISO: 'ZW' },
	{ name: 'country_israel', codeDigitalISO: 376, codeAlpha2ISO: 'IL' },
	{ name: 'country_india', codeDigitalISO: 356, codeAlpha2ISO: 'IN' },
	{ name: 'country_indonesia', codeDigitalISO: 360, codeAlpha2ISO: 'ID' },
	{ name: 'country_jordan', codeDigitalISO: 400, codeAlpha2ISO: 'JO' },
	{ name: 'country_iraq', codeDigitalISO: 368, codeAlpha2ISO: 'IQ' },
	{ name: 'country_iran', codeDigitalISO: 364, codeAlpha2ISO: 'IR' },
	{ name: 'country_ireland', codeDigitalISO: 372, codeAlpha2ISO: 'IE' },
	{ name: 'country_iceland', codeDigitalISO: 352, codeAlpha2ISO: 'IS' },
	{ name: 'country_spain', codeDigitalISO: 724, codeAlpha2ISO: 'ES' },
	{ name: 'country_italy', codeDigitalISO: 380, codeAlpha2ISO: 'IT' },
	{ name: 'country_yemen', codeDigitalISO: 887, codeAlpha2ISO: 'YE' },
	{ name: 'country_capeVerde', codeDigitalISO: 132, codeAlpha2ISO: 'CV' },
	{ name: 'country_kazakhstan', codeDigitalISO: 398, codeAlpha2ISO: 'KZ' },
	{ name: 'country_cambodia', codeDigitalISO: 116, codeAlpha2ISO: 'KH' },
	{ name: 'country_cameroon', codeDigitalISO: 120, codeAlpha2ISO: 'CM' },
	{ name: 'country_canada', codeDigitalISO: 124, codeAlpha2ISO: 'CA' },
	{ name: 'country_qatar', codeDigitalISO: 634, codeAlpha2ISO: 'QA' },
	{ name: 'country_kenya', codeDigitalISO: 404, codeAlpha2ISO: 'KE' },
	{ name: 'country_cyprus', codeDigitalISO: 196, codeAlpha2ISO: 'CY' },
	{ name: 'country_kyrgyzstan', codeDigitalISO: 417, codeAlpha2ISO: 'KG' },
	{ name: 'country_kiribati', codeDigitalISO: 296, codeAlpha2ISO: 'KI' },
	{ name: 'country_china', codeDigitalISO: 156, codeAlpha2ISO: 'CN' },
	{ name: 'country_colombia', codeDigitalISO: 170, codeAlpha2ISO: 'CO' },
	{ name: 'country_comoros', codeDigitalISO: 174, codeAlpha2ISO: 'KM' },
	{ name: 'country_republicOfTheCongo', codeDigitalISO: 178, codeAlpha2ISO: 'CG' },
	{ name: 'country_democraticRepublicOfTheCongo', codeDigitalISO: 180, codeAlpha2ISO: 'CD' },
	{ name: 'country_northKorea', codeDigitalISO: 408, codeAlpha2ISO: 'KP' },
	{ name: 'country_southKorea', codeDigitalISO: 410, codeAlpha2ISO: 'KR' },
	{ name: 'country_costaRica', codeDigitalISO: 188, codeAlpha2ISO: 'CR' },
	{ name: 'country_ivoryCoast', codeDigitalISO: 384, codeAlpha2ISO: 'CI' },
	{ name: 'country_cuba', codeDigitalISO: 192, codeAlpha2ISO: 'CU' },
	{ name: 'country_kuwait', codeDigitalISO: 414, codeAlpha2ISO: 'KW' },
	{ name: 'country_laos', codeDigitalISO: 418, codeAlpha2ISO: 'LA' },
	{ name: 'country_latvia', codeDigitalISO: 428, codeAlpha2ISO: 'LV' },
	{ name: 'country_lesotho', codeDigitalISO: 426, codeAlpha2ISO: 'LS' },
	{ name: 'country_liberia', codeDigitalISO: 430, codeAlpha2ISO: 'LR' },
	{ name: 'country_lebanon', codeDigitalISO: 422, codeAlpha2ISO: 'LB' },
	{ name: 'country_libya', codeDigitalISO: 434, codeAlpha2ISO: 'LY' },
	{ name: 'country_lithuania', codeDigitalISO: 440, codeAlpha2ISO: 'LT' },
	{ name: 'country_liechtenstein', codeDigitalISO: 438, codeAlpha2ISO: 'LI' },
	{ name: 'country_luxembourg', codeDigitalISO: 442, codeAlpha2ISO: 'LU' },
	{ name: 'country_mauritius', codeDigitalISO: 480, codeAlpha2ISO: 'MU' },
	{ name: 'country_mauritania', codeDigitalISO: 478, codeAlpha2ISO: 'MR' },
	{ name: 'country_madagascar', codeDigitalISO: 450, codeAlpha2ISO: 'MG' },
	{ name: 'country_malawi', codeDigitalISO: 454, codeAlpha2ISO: 'MW' },
	{ name: 'country_malaysia', codeDigitalISO: 458, codeAlpha2ISO: 'MY' },
	{ name: 'country_mali', codeDigitalISO: 466, codeAlpha2ISO: 'ML' },
	{ name: 'country_maldives', codeDigitalISO: 462, codeAlpha2ISO: 'MV' },
	{ name: 'country_malta', codeDigitalISO: 470, codeAlpha2ISO: 'MT' },
	{ name: 'country_morocco', codeDigitalISO: 504, codeAlpha2ISO: 'MA' },
	{ name: 'country_marshallIslands', codeDigitalISO: 584, codeAlpha2ISO: 'MH' },
	{ name: 'country_mexico', codeDigitalISO: 484, codeAlpha2ISO: 'MX' },
	{ name: 'country_micronesia', codeDigitalISO: 583, codeAlpha2ISO: 'FM' },
	{ name: 'country_mozambique', codeDigitalISO: 508, codeAlpha2ISO: 'MZ' },
	{ name: 'country_moldova', codeDigitalISO: 498, codeAlpha2ISO: 'MD' },
	{ name: 'country_monaco', codeDigitalISO: 492, codeAlpha2ISO: 'MC' },
	{ name: 'country_mongolia', codeDigitalISO: 496, codeAlpha2ISO: 'MN' },
	{ name: 'country_myanmar', codeDigitalISO: 104, codeAlpha2ISO: 'MM' },
	{ name: 'country_namibia', codeDigitalISO: 516, codeAlpha2ISO: 'NA' },
	{ name: 'country_nauru', codeDigitalISO: 520, codeAlpha2ISO: 'NR' },
	{ name: 'country_nepal', codeDigitalISO: 524, codeAlpha2ISO: 'NP' },
	{ name: 'country_niger', codeDigitalISO: 562, codeAlpha2ISO: 'NE' },
	{ name: 'country_nigeria', codeDigitalISO: 566, codeAlpha2ISO: 'NG' },
	{ name: 'country_netherlands', codeDigitalISO: 528, codeAlpha2ISO: 'NL' },
	{ name: 'country_nicaragua', codeDigitalISO: 558, codeAlpha2ISO: 'NI' },
	{ name: 'country_newZealand', codeDigitalISO: 554, codeAlpha2ISO: 'NZ' },
	{ name: 'country_norway', codeDigitalISO: 578, codeAlpha2ISO: 'NO' },
	{ name: 'country_unitedArabEmirates', codeDigitalISO: 784, codeAlpha2ISO: 'AE' },
	{ name: 'country_oman', codeDigitalISO: 512, codeAlpha2ISO: 'OM' },
	{ name: 'country_pakistan', codeDigitalISO: 586, codeAlpha2ISO: 'PK' },
	{ name: 'country_palau', codeDigitalISO: 585, codeAlpha2ISO: 'PW' },
	{ name: 'country_panama', codeDigitalISO: 591, codeAlpha2ISO: 'PA' },
	{ name: 'country_papuaNewGuinea', codeDigitalISO: 598, codeAlpha2ISO: 'PG' },
	{ name: 'country_paraguay', codeDigitalISO: 600, codeAlpha2ISO: 'PY' },
	{ name: 'country_peru', codeDigitalISO: 604, codeAlpha2ISO: 'PE' },
	{ name: 'country_poland', codeDigitalISO: 616, codeAlpha2ISO: 'PL' },
	{ name: 'country_portugal', codeDigitalISO: 620, codeAlpha2ISO: 'PT' },
	{ name: 'country_russia', codeDigitalISO: 643, codeAlpha2ISO: 'RU' },
	{ name: 'country_rwanda', codeDigitalISO: 646, codeAlpha2ISO: 'RW' },
	{ name: 'country_romania', codeDigitalISO: 642, codeAlpha2ISO: 'RO' },
	{ name: 'country_elSalvador', codeDigitalISO: 222, codeAlpha2ISO: 'SV' },
	{ name: 'country_samoa', codeDigitalISO: 882, codeAlpha2ISO: 'WS' },
	{ name: 'country_sanMarino', codeDigitalISO: 674, codeAlpha2ISO: 'SM' },
	{ name: 'country_saoTomeAndPrincipe', codeDigitalISO: 678, codeAlpha2ISO: 'ST' },
	{ name: 'country_saudiArabia', codeDigitalISO: 682, codeAlpha2ISO: 'SA' },
	{ name: 'country_northMacedonia', codeDigitalISO: 807, codeAlpha2ISO: 'MK' },
	{ name: 'country_seychelles', codeDigitalISO: 690, codeAlpha2ISO: 'SC' },
	{ name: 'country_senegal', codeDigitalISO: 686, codeAlpha2ISO: 'SN' },
	{ name: 'country_saintVincentAndTheGrenadines', codeDigitalISO: 670, codeAlpha2ISO: 'VC' },
	{ name: 'country_saintKittsAndNevis', codeDigitalISO: 659, codeAlpha2ISO: 'KN' },
	{ name: 'country_saintLucia', codeDigitalISO: 662, codeAlpha2ISO: 'LC' },
	{ name: 'country_serbia', codeDigitalISO: 688, codeAlpha2ISO: 'RS' },
	{ name: 'country_singapore', codeDigitalISO: 702, codeAlpha2ISO: 'SG' },
	{ name: 'country_syria', codeDigitalISO: 760, codeAlpha2ISO: 'SY' },
	{ name: 'country_slovakia', codeDigitalISO: 703, codeAlpha2ISO: 'SK' },
	{ name: 'country_slovenia', codeDigitalISO: 705, codeAlpha2ISO: 'SI' },
	{ name: 'country_unitedStates', codeDigitalISO: 840, codeAlpha2ISO: 'US' },
	{ name: 'country_solomonIslands', codeDigitalISO: 90, codeAlpha2ISO: 'SB' },
	{ name: 'country_somalia', codeDigitalISO: 706, codeAlpha2ISO: 'SO' },
	{ name: 'country_sudan', codeDigitalISO: 729, codeAlpha2ISO: 'SD' },
	{ name: 'country_suriname', codeDigitalISO: 740, codeAlpha2ISO: 'SR' },
	{ name: 'country_sierraLeone', codeDigitalISO: 694, codeAlpha2ISO: 'SL' },
	{ name: 'country_tajikistan', codeDigitalISO: 762, codeAlpha2ISO: 'TJ' },
	{ name: 'country_thailand', codeDigitalISO: 764, codeAlpha2ISO: 'TH' },
	{ name: 'country_tanzania', codeDigitalISO: 834, codeAlpha2ISO: 'TZ' },
	{ name: 'country_togo', codeDigitalISO: 768, codeAlpha2ISO: 'TG' },
	{ name: 'country_tonga', codeDigitalISO: 776, codeAlpha2ISO: 'TO' },
	{ name: 'country_trinidadAndTobago', codeDigitalISO: 780, codeAlpha2ISO: 'TT' },
	{ name: 'country_tuvalu', codeDigitalISO: 798, codeAlpha2ISO: 'TV' },
	{ name: 'country_tunisia', codeDigitalISO: 788, codeAlpha2ISO: 'TN' },
	{ name: 'country_turkmenistan', codeDigitalISO: 795, codeAlpha2ISO: 'TM' },
	{ name: 'country_turkey', codeDigitalISO: 792, codeAlpha2ISO: 'TR' },
	{ name: 'country_uganda', codeDigitalISO: 800, codeAlpha2ISO: 'UG' },
	{ name: 'country_uzbekistan', codeDigitalISO: 860, codeAlpha2ISO: 'UZ' },
	{ name: 'country_ukraine', codeDigitalISO: 804, codeAlpha2ISO: 'UA' },
	{ name: 'country_uruguay', codeDigitalISO: 858, codeAlpha2ISO: 'UY' },
	{ name: 'country_fiji', codeDigitalISO: 242, codeAlpha2ISO: 'FJ' },
	{ name: 'country_philippines', codeDigitalISO: 608, codeAlpha2ISO: 'PH' },
	{ name: 'country_finland', codeDigitalISO: 246, codeAlpha2ISO: 'FI' },
	{ name: 'country_france', codeDigitalISO: 250, codeAlpha2ISO: 'FR' },
	{ name: 'country_croatia', codeDigitalISO: 191, codeAlpha2ISO: 'HR' },
	{ name: 'country_centralAfricanRepublic', codeDigitalISO: 140, codeAlpha2ISO: 'CF' },
	{ name: 'country_chad', codeDigitalISO: 148, codeAlpha2ISO: 'TD' },
	{ name: 'country_montenegro', codeDigitalISO: 499, codeAlpha2ISO: 'ME' },
	{ name: 'country_czechRepublic', codeDigitalISO: 203, codeAlpha2ISO: 'CZ' },
	{ name: 'country_chile', codeDigitalISO: 152, codeAlpha2ISO: 'CL' },
	{ name: 'country_switzerland', codeDigitalISO: 756, codeAlpha2ISO: 'CH' },
	{ name: 'country_sweden', codeDigitalISO: 752, codeAlpha2ISO: 'SE' },
	{ name: 'country_sriLanka', codeDigitalISO: 144, codeAlpha2ISO: 'LK' },
	{ name: 'country_ecuador', codeDigitalISO: 218, codeAlpha2ISO: 'EC' },
	{ name: 'country_equatorialGuinea', codeDigitalISO: 226, codeAlpha2ISO: 'GQ' },
	{ name: 'country_eritrea', codeDigitalISO: 232, codeAlpha2ISO: 'ER' },
	{ name: 'country_eswatini', codeDigitalISO: 748, codeAlpha2ISO: 'SZ' },
	{ name: 'country_estonia', codeDigitalISO: 233, codeAlpha2ISO: 'EE' },
	{ name: 'country_ethiopia', codeDigitalISO: 231, codeAlpha2ISO: 'ET' },
	{ name: 'country_southAfrica', codeDigitalISO: 710, codeAlpha2ISO: 'ZA' },
	{ name: 'country_southSudan', codeDigitalISO: 728, codeAlpha2ISO: 'SS' },
	{ name: 'country_jamaica', codeDigitalISO: 388, codeAlpha2ISO: 'JM' },
	{ name: 'country_japan', codeDigitalISO: 392, codeAlpha2ISO: 'JP' },
];

const countryRuNames = new Map<string, string>([
	['country_australia', 'Австралия'],
	['country_austria', 'Австрия'],
	['country_azerbaijan', 'Азербайджан'],
	['country_albania', 'Албания'],
	['country_algeria', 'Алжир'],
	['country_angola', 'Ангола'],
	['country_andorra', 'Андорра'],
	['country_antiguaAndBarbuda', 'Антигуа и Барбуда'],
	['country_argentina', 'Аргентина'],
	['country_armenia', 'Армения'],
	['country_afghanistan', 'Афганистан'],
	['country_bahamas', 'Багамы'],
	['country_bangladesh', 'Бангладеш'],
	['country_barbados', 'Барбадос'],
	['country_bahrain', 'Бахрейн'],
	['country_belize', 'Белиз'],
	['country_belarus', 'Белоруссия'],
	['country_belgium', 'Бельгия'],
	['country_benin', 'Бенин'],
	['country_bulgaria', 'Болгария'],
	['country_bolivia', 'Боливия'],
	['country_bosniaAndHerzegovina', 'Босния и Герцеговина'],
	['country_botswana', 'Ботсвана'],
	['country_brazil', 'Бразилия'],
	['country_brunei', 'Бруней'],
	['country_burkinaFaso', 'Буркина-Фасо'],
	['country_burundi', 'Бурунди'],
	['country_bhutan', 'Бутан'],
	['country_vanuatu', 'Вануату'],
	['country_unitedKingdom', 'Великобритания'],
	['country_hungary', 'Венгрия'],
	['country_venezuela', 'Венесуэла'],
	['country_eastTimor', 'Восточный Тимор'],
	['country_vietnam', 'Вьетнам'],
	['country_gabon', 'Габон'],
	['country_haiti', 'Гаити'],
	['country_guyana', 'Гайана'],
	['country_gambia', 'Гамбия'],
	['country_ghana', 'Гана'],
	['country_guatemala', 'Гватемала'],
	['country_guinea', 'Гвинея'],
	['country_guineaBissau', 'Гвинея-Бисау'],
	['country_germany', 'Германия'],
	['country_honduras', 'Гондурас'],
	['country_grenada', 'Гренада'],
	['country_greece', 'Греция'],
	['country_georgia', 'Грузия'],
	['country_denmark', 'Дания'],
	['country_djibouti', 'Джибути'],
	['country_dominica', 'Доминика'],
	['country_dominicanRepublic', 'Доминикана'],
	['country_egypt', 'Египет'],
	['country_zambia', 'Замбия'],
	['country_zimbabwe', 'Зимбабве'],
	['country_israel', 'Израиль'],
	['country_india', 'Индия'],
	['country_indonesia', 'Индонезия'],
	['country_jordan', 'Иордания'],
	['country_iraq', 'Ирак'],
	['country_iran', 'Иран'],
	['country_ireland', 'Ирландия'],
	['country_iceland', 'Исландия'],
	['country_spain', 'Испания'],
	['country_italy', 'Италия'],
	['country_yemen', 'Йемен'],
	['country_capeVerde', 'Кабо-Верде'],
	['country_kazakhstan', 'Казахстан'],
	['country_cambodia', 'Камбоджа'],
	['country_cameroon', 'Камерун'],
	['country_canada', 'Канада'],
	['country_qatar', 'Катар'],
	['country_kenya', 'Кения'],
	['country_cyprus', 'Кипр'],
	['country_kyrgyzstan', 'Киргизия'],
	['country_kiribati', 'Кирибати'],
	['country_china', 'Китай'],
	['country_colombia', 'Колумбия'],
	['country_comoros', 'Коморы'],
	['country_republicOfTheCongo', 'Конго'],
	['country_democraticRepublicOfTheCongo', 'ДР Конго'],
	['country_northKorea', 'КНДР'],
	['country_southKorea', 'Корея'],
	['country_costaRica', 'Коста-Рика'],
	['country_ivoryCoast', "Кот-д'Ивуар"],
	['country_cuba', 'Куба'],
	['country_kuwait', 'Кувейт'],
	['country_laos', 'Лаос'],
	['country_latvia', 'Латвия'],
	['country_lesotho', 'Лесото'],
	['country_liberia', 'Либерия'],
	['country_lebanon', 'Ливан'],
	['country_libya', 'Ливия'],
	['country_lithuania', 'Литва'],
	['country_liechtenstein', 'Лихтенштейн'],
	['country_luxembourg', 'Люксембург'],
	['country_mauritius', 'Маврикий'],
	['country_mauritania', 'Мавритания'],
	['country_madagascar', 'Мадагаскар'],
	['country_malawi', 'Малави'],
	['country_malaysia', 'Малайзия'],
	['country_mali', 'Мали'],
	['country_maldives', 'Мальдивы'],
	['country_malta', 'Мальта'],
	['country_morocco', 'Марокко'],
	['country_marshallIslands', 'Маршалловы Острова'],
	['country_mexico', 'Мексика'],
	['country_micronesia', 'Микронезия'],
	['country_mozambique', 'Мозамбик'],
	['country_moldova', 'Молдавия'],
	['country_monaco', 'Монако'],
	['country_mongolia', 'Монголия'],
	['country_myanmar', 'Мьянма'],
	['country_namibia', 'Намибия'],
	['country_nauru', 'Науру'],
	['country_nepal', 'Непал'],
	['country_niger', 'Нигер'],
	['country_nigeria', 'Нигерия'],
	['country_netherlands', 'Нидерланды'],
	['country_nicaragua', 'Никарагуа'],
	['country_newZealand', 'Новая Зеландия'],
	['country_norway', 'Норвегия'],
	['country_unitedArabEmirates', 'ОАЭ'],
	['country_oman', 'Оман'],
	['country_pakistan', 'Пакистан'],
	['country_palau', 'Палау'],
	['country_panama', 'Панама'],
	['country_papuaNewGuinea', 'Папуа — Новая Гвинея'],
	['country_paraguay', 'Парагвай'],
	['country_peru', 'Перу'],
	['country_poland', 'Польша'],
	['country_portugal', 'Португалия'],
	['country_russia', 'Россия'],
	['country_rwanda', 'Руанда'],
	['country_romania', 'Румыния'],
	['country_elSalvador', 'Сальвадор'],
	['country_samoa', 'Самоа'],
	['country_sanMarino', 'Сан-Марино'],
	['country_saoTomeAndPrincipe', 'Сан-Томе и Принсипи'],
	['country_saudiArabia', 'Саудовская Аравия'],
	['country_northMacedonia', 'Северная Македония'],
	['country_seychelles', 'Сейшелы'],
	['country_senegal', 'Сенегал'],
	['country_saintVincentAndTheGrenadines', 'Сент-Винсент и Гренадины'],
	['country_saintKittsAndNevis', 'Сент-Китс и Невис'],
	['country_saintLucia', 'Сент-Люсия'],
	['country_serbia', 'Сербия'],
	['country_singapore', 'Сингапур'],
	['country_syria', 'Сирия'],
	['country_slovakia', 'Словакия'],
	['country_slovenia', 'Словения'],
	['country_unitedStates', 'США'],
	['country_solomonIslands', 'Соломоновы Острова'],
	['country_somalia', 'Сомали'],
	['country_sudan', 'Судан'],
	['country_suriname', 'Суринам'],
	['country_sierraLeone', 'Сьерра-Леоне'],
	['country_tajikistan', 'Таджикистан'],
	['country_thailand', 'Таиланд'],
	['country_tanzania', 'Танзания'],
	['country_togo', 'Того'],
	['country_tonga', 'Тонга'],
	['country_trinidadAndTobago', 'Тринидад и Тобаго'],
	['country_tuvalu', 'Тувалу'],
	['country_tunisia', 'Тунис'],
	['country_turkmenistan', 'Туркменистан'],
	['country_turkey', 'Турция'],
	['country_uganda', 'Уганда'],
	['country_uzbekistan', 'Узбекистан'],
	['country_ukraine', 'Украина'],
	['country_uruguay', 'Уругвай'],
	['country_fiji', 'Фиджи'],
	['country_philippines', 'Филиппины'],
	['country_finland', 'Финляндия'],
	['country_france', 'Франция'],
	['country_croatia', 'Хорватия'],
	['country_centralAfricanRepublic', 'ЦАР'],
	['country_chad', 'Чад'],
	['country_montenegro', 'Черногория'],
	['country_czechRepublic', 'Чехия'],
	['country_chile', 'Чили'],
	['country_switzerland', 'Швейцария'],
	['country_sweden', 'Швеция'],
	['country_sriLanka', 'Шри-Ланка'],
	['country_ecuador', 'Эквадор'],
	['country_equatorialGuinea', 'Экваториальная Гвинея'],
	['country_eritrea', 'Эритрея'],
	['country_eswatini', 'Эсватини'],
	['country_estonia', 'Эстония'],
	['country_ethiopia', 'Эфиопия'],
	['country_southAfrica', 'ЮАР'],
	['country_southSudan', 'Южный Судан'],
	['country_jamaica', 'Ямайка'],
	['country_japan', 'Япония'],
	['country_not_selected', 'Не указана'],
]);

const countryEnNames = new Map<string, string>([
	['country_australia', 'Australia'],
	['country_austria', 'Austria'],
	['country_azerbaijan', 'Azerbaijan'],
	['country_albania', 'Albania'],
	['country_algeria', 'Algeria'],
	['country_angola', 'Angola'],
	['country_andorra', 'Andorra'],
	['country_antiguaAndBarbuda', 'Antigua and Barbuda'],
	['country_argentina', 'Argentina'],
	['country_armenia', 'Armenia'],
	['country_afghanistan', 'Afghanistan'],
	['country_bahamas', 'Bahamas'],
	['country_bangladesh', 'Bangladesh'],
	['country_barbados', 'Barbados'],
	['country_bahrain', 'Bahrain'],
	['country_belize', 'Belize'],
	['country_belarus', 'Belarus'],
	['country_belgium', 'Belgium'],
	['country_benin', 'Benin'],
	['country_bulgaria', 'Bulgaria'],
	['country_bolivia', 'Bolivia'],
	['country_bosniaAndHerzegovina', 'Bosnia and Herzegovina'],
	['country_botswana', 'Botswana'],
	['country_brazil', 'Brazil'],
	['country_brunei', 'Brunei'],
	['country_burkinaFaso', 'Burkina Faso'],
	['country_burundi', 'Burundi'],
	['country_bhutan', 'Bhutan'],
	['country_vanuatu', 'Vanuatu'],
	['country_unitedKingdom', 'United Kingdom'],
	['country_hungary', 'Hungary'],
	['country_venezuela', 'Venezuela'],
	['country_eastTimor', 'Timor-Leste'],
	['country_vietnam', 'Vietnam'],
	['country_gabon', 'Gabon'],
	['country_haiti', 'Haiti'],
	['country_guyana', 'Guyana'],
	['country_gambia', 'Gambia'],
	['country_ghana', 'Ghana'],
	['country_guatemala', 'Guatemala'],
	['country_guinea', 'Guinea'],
	['country_guineaBissau', 'Guinea-Bissau'],
	['country_germany', 'Germany'],
	['country_honduras', 'Honduras'],
	['country_grenada', 'Grenada'],
	['country_greece', 'Greece'],
	['country_georgia', 'Georgia'],
	['country_denmark', 'Denmark'],
	['country_djibouti', 'Djibouti'],
	['country_dominica', 'Dominica'],
	['country_dominicanRepublic', 'Dominican Republic'],
	['country_egypt', 'Egypt'],
	['country_zambia', 'Zambia'],
	['country_zimbabwe', 'Zimbabwe'],
	['country_israel', 'Israel'],
	['country_india', 'India'],
	['country_indonesia', 'Indonesia'],
	['country_jordan', 'Jordan'],
	['country_iraq', 'Iraq'],
	['country_iran', 'Iran'],
	['country_ireland', 'Ireland'],
	['country_iceland', 'Iceland'],
	['country_spain', 'Spain'],
	['country_italy', 'Italy'],
	['country_yemen', 'Yemen'],
	['country_capeVerde', 'Cabo Verde'],
	['country_kazakhstan', 'Kazakhstan'],
	['country_cambodia', 'Cambodia'],
	['country_cameroon', 'Cameroon'],
	['country_canada', 'Canada'],
	['country_qatar', 'Qatar'],
	['country_kenya', 'Kenya'],
	['country_cyprus', 'Cyprus'],
	['country_kyrgyzstan', 'Kyrgyzstan'],
	['country_kiribati', 'Kiribati'],
	['country_china', 'China'],
	['country_colombia', 'Colombia'],
	['country_comoros', 'Comoros'],
	['country_republicOfTheCongo', 'Congo'],
	['country_democraticRepublicOfTheCongo', 'DR Congo'],
	['country_northKorea', 'North Korea'],
	['country_southKorea', 'South Korea'],
	['country_costaRica', 'Costa Rica'],
	['country_ivoryCoast', "Côte d'Ivoire"],
	['country_cuba', 'Cuba'],
	['country_kuwait', 'Kuwait'],
	['country_laos', 'Laos'],
	['country_latvia', 'Latvia'],
	['country_lesotho', 'Lesotho'],
	['country_liberia', 'Liberia'],
	['country_lebanon', 'Lebanon'],
	['country_libya', 'Libya'],
	['country_lithuania', 'Lithuania'],
	['country_liechtenstein', 'Liechtenstein'],
	['country_luxembourg', 'Luxembourg'],
	['country_mauritius', 'Mauritius'],
	['country_mauritania', 'Mauritania'],
	['country_madagascar', 'Madagascar'],
	['country_malawi', 'Malawi'],
	['country_malaysia', 'Malaysia'],
	['country_mali', 'Mali'],
	['country_maldives', 'Maldives'],
	['country_malta', 'Malta'],
	['country_morocco', 'Morocco'],
	['country_marshallIslands', 'Marshall Islands'],
	['country_mexico', 'Mexico'],
	['country_micronesia', 'Micronesia'],
	['country_mozambique', 'Mozambique'],
	['country_moldova', 'Moldova'],
	['country_monaco', 'Monaco'],
	['country_mongolia', 'Mongolia'],
	['country_myanmar', 'Myanmar'],
	['country_namibia', 'Namibia'],
	['country_nauru', 'Nauru'],
	['country_nepal', 'Nepal'],
	['country_niger', 'Niger'],
	['country_nigeria', 'Nigeria'],
	['country_netherlands', 'Netherlands'],
	['country_nicaragua', 'Nicaragua'],
	['country_newZealand', 'New Zealand'],
	['country_norway', 'Norway'],
	['country_unitedArabEmirates', 'United Arab Emirates'],
	['country_oman', 'Oman'],
	['country_pakistan', 'Pakistan'],
	['country_palau', 'Palau'],
	['country_panama', 'Panama'],
	['country_papuaNewGuinea', 'Papua New Guinea'],
	['country_paraguay', 'Paraguay'],
	['country_peru', 'Peru'],
	['country_poland', 'Poland'],
	['country_portugal', 'Portugal'],
	['country_russia', 'Russia'],
	['country_rwanda', 'Rwanda'],
	['country_romania', 'Romania'],
	['country_elSalvador', 'El Salvador'],
	['country_samoa', 'Samoa'],
	['country_sanMarino', 'San Marino'],
	['country_saoTomeAndPrincipe', 'São Tomé and Príncipe'],
	['country_saudiArabia', 'Saudi Arabia'],
	['country_northMacedonia', 'North Macedonia'],
	['country_seychelles', 'Seychelles'],
	['country_senegal', 'Senegal'],
	['country_saintVincentAndTheGrenadines', 'Saint Vincent and the Grenadines'],
	['country_saintKittsAndNevis', 'Saint Kitts and Nevis'],
	['country_saintLucia', 'Saint Lucia'],
	['country_serbia', 'Serbia'],
	['country_singapore', 'Singapore'],
	['country_syria', 'Syria'],
	['country_slovakia', 'Slovakia'],
	['country_slovenia', 'Slovenia'],
	['country_unitedStates', 'United States'],
	['country_solomonIslands', 'Solomon Islands'],
	['country_somalia', 'Somalia'],
	['country_sudan', 'Sudan'],
	['country_suriname', 'Suriname'],
	['country_sierraLeone', 'Sierra Leone'],
	['country_tajikistan', 'Tajikistan'],
	['country_thailand', 'Thailand'],
	['country_tanzania', 'Tanzania'],
	['country_togo', 'Togo'],
	['country_tonga', 'Tonga'],
	['country_trinidadAndTobago', 'Trinidad and Tobago'],
	['country_tuvalu', 'Tuvalu'],
	['country_tunisia', 'Tunisia'],
	['country_turkmenistan', 'Turkmenistan'],
	['country_turkey', 'Turkey'],
	['country_uganda', 'Uganda'],
	['country_uzbekistan', 'Uzbekistan'],
	['country_ukraine', 'Ukraine'],
	['country_uruguay', 'Uruguay'],
	['country_fiji', 'Fiji'],
	['country_philippines', 'Philippines'],
	['country_finland', 'Finland'],
	['country_france', 'France'],
	['country_croatia', 'Croatia'],
	['country_centralAfricanRepublic', 'Central African Republic'],
	['country_chad', 'Chad'],
	['country_montenegro', 'Montenegro'],
	['country_czechRepublic', 'Czech Republic'],
	['country_chile', 'Chile'],
	['country_switzerland', 'Switzerland'],
	['country_sweden', 'Sweden'],
	['country_sriLanka', 'Sri Lanka'],
	['country_ecuador', 'Ecuador'],
	['country_equatorialGuinea', 'Equatorial Guinea'],
	['country_eritrea', 'Eritrea'],
	['country_eswatini', 'Eswatini'],
	['country_estonia', 'Estonia'],
	['country_ethiopia', 'Ethiopia'],
	['country_southAfrica', 'South Africa'],
	['country_southSudan', 'South Sudan'],
	['country_jamaica', 'Jamaica'],
	['country_japan', 'Japan'],
	['country_not_selected', 'Not selected'],
]);

export const countryNames = new Map<Locale, Map<string, string>>([
	['ru', countryRuNames],
	['en', countryEnNames],
]);
