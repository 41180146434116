export const setCookie = (name: string, value: string) => {
	if (typeof window === 'undefined' || typeof document === 'undefined') {
		return;
	}

	document.cookie = `${name}=${value}`;
};

export const deleteCookie = (name: string) => {
	setCookie(name, ' ;max-age=-1');
};

export const getCookie = (name: string): string | undefined => {
	if (typeof window === 'undefined' || typeof document === 'undefined') {
		return undefined;
	}

	const cookies = document.cookie;

	const cookiesArray = cookies.split(';').map((cookie) => cookie.trimStart());
	const currentCookie = cookiesArray.find((cookie) => cookie.startsWith(name));

	return currentCookie?.split('=')?.[1];
};
