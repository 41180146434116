import { useCallback, useEffect, useMemo, useState } from 'react';

import { getHostUrl, isLocalEnvironment } from '@/environment';
import { getAccessTokenFixtureFromCookies } from '@/src/modules/auth/utils/auth.utils';
import {
	isCorruptedResponse,
	ResponseError,
} from '@/src/modules/common/utils/errors/ResponseError';
import useSWR from 'swr';

// https://swr.vercel.app/docs/api#options
const defaultSWROptions = {
	revalidateOnFocus: false,
	refreshInterval: 0,
};

export type UseGETReloadFunction = () => void;

export interface UseMETHODOptions {
	endpoint?: string | (() => string);
	apiVersion?: number;
	isFullEndpoint?: boolean;
}

export type UseGETOptions = {
	canGet?: boolean;
	headers?: HeadersInit;
} & UseMETHODOptions;

export type UseGET<TData, TError = unknown> = {
	data: TData | undefined;
	error?: TError;
	isLoading: boolean;
	reload: UseGETReloadFunction;
	silentReload: UseGETReloadFunction;
};

// const fetcher = (url: string) => {
// 	let headers = undefined;
// 	let credentials: RequestCredentials | undefined = 'include';
//
// 	if (isLocalEnvironment) {
// 		const accessToken = getAccessTokenFixtureFromCookies();
// 		headers = { authorization: `Bearer ${accessToken}` };
// 		credentials = undefined;
// 	}
//
// 	return fetch(url, { credentials, headers }).then((res) => res.json());
// };

const createFetcher = (url: string | null | undefined, headersInit?: HeadersInit) => {
	return (url: string) => {
		let headers = undefined;
		let credentials: RequestCredentials | undefined = 'include';

		if (isLocalEnvironment) {
			const accessToken = getAccessTokenFixtureFromCookies();
			headers = { authorization: `Bearer ${accessToken}` };
			credentials = undefined;
		}

		headers = { ...headers, ...headersInit };

		return fetch(url, { credentials, headers })
			.then((res) => {
				return res.json();
			})
			.then((res) => {
				if (!isCorruptedResponse(res)) {
					return res;
				}

				throw new ResponseError(res);
			});
	};
};

export const useGET = <TData, TError>({
	endpoint,
	apiVersion = 2,
	canGet = true,
	headers,
	isFullEndpoint = false,
}: UseGETOptions): UseGET<TData, TError> => {
	const [url, setUrl] = useState<string | null>();
	const [dataState, setDataState] = useState<TData>();
	const [errorState, setErrorState] = useState<TError>();
	const [isLoadingState, setIsLoadingState] = useState<boolean>(true);

	const skipIsLoading = useMemo<{ value: boolean }>(() => ({ value: false }), []);

	const { data, error, isLoading, mutate } = useSWR<TData, TError>(
		url,
		createFetcher(url, headers),
		{
			...defaultSWROptions,
			onSuccess: () => {
				setIsLoadingState(false);
			},
		},
	);

	useEffect(() => {
		const url = canGet
			? isFullEndpoint
				? typeof endpoint === 'function'
					? endpoint()
					: endpoint
				: typeof endpoint === 'function'
					? `${getHostUrl()}api/v${apiVersion}${endpoint()}`
					: `${getHostUrl()}api/v${apiVersion}${endpoint}`
			: null;

		setUrl(url);
	}, [canGet, endpoint]);

	useEffect(() => {
		setDataState(data);
	}, [data]);

	useEffect(() => {
		setErrorState(error);
	}, [error]);

	useEffect(() => {
		if (skipIsLoading.value) {
			skipIsLoading.value = false;
			return;
		}

		setIsLoadingState(isLoading);
	}, [isLoading]);

	const reload = useCallback(() => {
		setIsLoadingState(true);

		// здесь в первом параметре можно передать undefined, чтобы была перезагрузка data,
		// либо оставить data и тогда будет валидация кеша
		mutate(undefined, { revalidate: true })
			.then((response) => {
				setDataState(response);

				return response as TData;
			})
			.catch((error) => {
				setErrorState(error);

				return undefined;
			});
	}, []);

	const silentReload = useCallback(() => {
		skipIsLoading.value = true;

		// здесь в первом параметре можно передать undefined, чтобы была перезагрузка data,
		// либо оставить data и тогда будет валидация кеша
		mutate(undefined, { revalidate: true })
			.then((response) => {
				setDataState(response);

				return response as TData;
			})
			.catch((error) => {
				setErrorState(error);

				return undefined;
			});
	}, []);

	return useMemo(
		() => ({
			data: dataState,
			error: errorState,
			isLoading: isLoadingState,
			reload,
			silentReload,
		}),
		[dataState, errorState, isLoadingState, reload, silentReload],
	);
};
